import React from 'react';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import ApolloTree from './ApolloTree';
import { Link } from 'react-router-dom';
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 10,
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <MenuItem>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" component={Link} to="/">
              <HomeIcon />
            </IconButton>
          </MenuItem>
          <MenuItem>
            <Typography className={classes.menuButton} style={{fontSize: "2 rem", fontWeight: 'bold'}}>
              Ultima
            </Typography>
          </MenuItem>
          <MenuItem>
            <ApolloTree className={classes.menuButton} title={"Apollo 12"} path={"apollo12"}/>
          </MenuItem>
          <MenuItem>
            <ApolloTree className={classes.menuButton} title={"Apollo 15"} path={"apollo15"}/>
          </MenuItem>
          <MenuItem>
            <ApolloTree className={classes.menuButton} title={"Apollo 16"} path={"apollo16"}/>
          </MenuItem>
        </Toolbar>
      </AppBar>
    </div>
  );
}
